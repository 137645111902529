
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Table } from "@/utils/decorator";
import { getImageSize, emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { isMoney, isPositiveIntegerZero } from "@/utils/verify";
import t from "@common/src/i18n/t";
import FileUploadList from "@/components/scope/file-upload-list.vue";

const app = namespace("app");
const store = namespace("store");
const campaign = namespace("campaign");
const coupon = namespace("coupon");
const fission = namespace("fission");
const base = namespace("base");
const marketing = namespace("marketing");
const userTask = namespace("userTask");
const management = namespace("management");

@Component({
  components: { FileUpload, Editor, FileUploadList }
})
@Table("loadListData", { pageSize: 5 })
export default class CollectCustomerEdit extends Vue {
  @store.State storeData;
  @campaign.Action createSaveCampaign;
  @campaign.Action queryCampaignDetail;
  @base.Action getDictionaryList;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @coupon.Action queryList;
  @app.Mutation setPBPX;


  @userTask.Action userTaskSave;
  @userTask.Action userTaskDetail;

  emptyToLine = emptyToLine;
  dayjs = dayjs;

  defaultUserTaskFixTemplateUrl: string = "https://speedbiz-saas-public.oss-cn-beijing.aliyuncs.com/dev/speedbiz/others/importTemplate/user_task_fix_user.xlsx";
  get isAdd() {
    return this.$route.name === "userTaskConfAdd";
  }
  get breadData() {
    return [
      { name: "用户任务配置", path: "/userTask/conf/index" },
      {
        name: this.isAdd ? "添加任务" : "编辑任务"
      }
    ];
  }

  conditionDefine: any = {
    type: "", // 条件类型
    value: "",  // 条件值
    fileList: []
  }
  taskTypeList: any = [
    {
      label: "新手任务",
      value: 1
    },
    {
      label: "日常任务",
      value: 2
    }
  ];
  conditionTypeList: any = [
    // {
    //   label: "固定城市",
    //   value: 1
    // },
    {
      label: "固定用户",
      value: 2
    },
    {
      label: "新老用户",
      value: 3
    }
  ]

  taskForm: any = {
    taskNo: "",
    name: "",
    taskTimeRange: "",
    startTime: "",
    endTime: "",
    participateLimit: "",            //用户限制
    type: "",
    points: 0, //任务领取积分
    experience: 0, //任务领取经验值
    isShow: 1, //是否展示
    appJumpUrl: "",
    miniJumpUrl: "",
    h5JumpUrl: "",
    icon: "",
    remark: "",
    completionExpression: "",
    conditionGroups: []
  };

  taskFormRules: any = {
    name: [
      { required: true, message:"请填写任务名称"}
    ],
    taskTimeRange: {
      type: "array",
      required: true,
      message: '请选择任务时间',
      trigger: "change"
    },
    icon: { required: true, validator: this.checkFrontImage },
    participateLimit: { required: true, validator: this.checkParticipateLimit, trigger: "change"},
    type: { required: true, message: "请选择任务类型"},
    completionExpression: { required: true, message: "请填写完成条件"}
  }
  checkFrontImage(rule, value, callback) {
    if (!value) {
      callback(new Error("请上传图片"));
    } else {
      callback();
    }
  }

  checkParticipateLimit(rule, value, callback) {
    if (!value) {
      callback(new Error("请选择用户限制"));
    } else {
      if (value === 2) {
        if (this.taskForm.conditionGroups.length < 1) {
          callback(new Error("至少添加一个用户限制群体"));
        } else {
          callback();
        }
      }else {
        callback();
      }
    }
  }

  validateConditionGroup(rule, value, callback) {
    let conditionGroup = this.taskForm.conditionGroups[rule.index];
    if (!conditionGroup || conditionGroup.length < 1) {
      callback(new Error("至少添加一个条件"));
    } else {
      const newArr = conditionGroup.map((item) => item.type);
      //如果有重复的isRepeat返回true，没有重复返回false
      const isRepeat = newArr.some((item, index, arr) => arr.indexOf(item) != index );
      if (isRepeat) {
        callback(new Error("不可添加相同类型的条件"));
      }else {
        callback();
      }
    }
  }

  validateCondition(rule, value, callback) {
    let condition = this.taskForm.conditionGroups[rule.groupIndex][rule.index];
    if (!condition.type) {
      callback(new Error("请选择条件类型"));
    }else {
      if (condition.type == 2 && !condition.value) {
        callback(new Error("请上传用户列表"));
      }else if (condition.type == 3 && !condition.value) {
        callback(new Error("请选择新老用户"));
      } else {
        callback();
      }
    }
  }

  validateParticipateLimit(){
    (this.$refs.taskForm as any).validateField("participateLimit");
  }

  handleAddConditionGroup() {
    this.taskForm.conditionGroups.push([]);
    this.validateParticipateLimit();
  }
  handleRemoveConditionGroup(groupIndex) {
    this.taskForm.conditionGroups.splice(groupIndex, 1);
    this.validateParticipateLimit();
  }

  handleAddCondition(groupIndex) {
    this.taskForm.conditionGroups[groupIndex].push(Object.assign({}, this.conditionDefine));
  }

  handleRemoveCondition(groupIndex, conditionIndex) {
    this.taskForm.conditionGroups[groupIndex].splice(conditionIndex, 1);
  }

  defaultIconList: any = []
  created() {
    this.setPBPX(52);
    this.init();
  }
  get taskNo() {
    return this.$route.params.taskNo;
  }

  init() {
    // 获取详情
    if(this.taskNo){
      this.userTaskDetail({taskNo: this.taskNo}).then(res => {
        Object.keys(res.data).forEach(key => {
          this.taskForm[key] = res.data[key];
        })
        this.taskForm['taskTimeRange'] = [res.data['startTime'], res.data['endTime']];
        this.defaultIconList = [{url: res.data['icon']}];
        let conditionGroups = res.data['conditionGroups'];
        if (conditionGroups) {
          conditionGroups.forEach(group => {
            group.forEach(condition => {
              if (condition.type == 2) {
                let fileUrl = condition.value;
                let  fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
                condition.fileList = [{name: fileName, url: fileUrl}]
              }
            })
          })
        }
      })
    }

    // this.getDictionaryList("store_type").then(data => {
    //   this.storeTypesList = data;
    // });

  }
  mounted() {}


  saveTask() {
    (this.$refs.taskForm as any).validate(valid => {
      if(valid){
        if (this.taskForm.taskTimeRange) {
          this.taskForm.startTime = this.taskForm.taskTimeRange[0];
          this.taskForm.endTime = this.taskForm.taskTimeRange[1];
        }
        this.userTaskSave({
          ...this.taskForm,
          }).then(res => {
          console.log(res)
          if(res.code==0){
            this.$router.go(-1);
          }else{
            this.$message.error('创建失败，请稍后重试！')
          }
        })
      }
      return
    });
  }
}
